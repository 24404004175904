import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Navbar from './navbar';
import {
  Sidebar,
  SidebarProvider,
  drawerClosedWidth,
  drawerWidth,
  useSidebar,
} from './sidebar';

export default function MainLayout({
  children,
  noGutters = false,
  backgroundColor,
}) {
  return (
    <SidebarProvider>
      <Box sx={{display: 'flex'}}>
        <Navbar />
        <Box
          component="nav"
          sx={{
            flexShrink: {sm: 0},
            position: 'fixed',
          }}
          aria-label="sidebar mobile"
        >
          <Sidebar />
        </Box>
        <MainContent noGutters={noGutters} backgroundColor={backgroundColor}>
          {children}
        </MainContent>
      </Box>
    </SidebarProvider>
  );
}

function MainContent({children, noGutters, backgroundColor}) {
  const isOpen = useSidebar().isOpen;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        width: isOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
        mt: isMobile ? '56px' : '0',
        pt: noGutters ? '0' : '25px',
        backgroundColor: backgroundColor || '#F3F4F8',
        marginLeft: isMobile
          ? 0
          : isOpen
          ? `${drawerWidth}px`
          : `${drawerClosedWidth}px`,
        transition: 'margin-left 0.3s',
        minHeight: isMobile ? 'calc(100vh - 56px)' : '100vh',
      }}
    >
      <Container maxWidth={false} disableGutters={noGutters}>
        {children}
      </Container>
    </Box>
  );
}
