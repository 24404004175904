import { Form } from '@/components/generic/forms/Form';
import { FormSubmitButton } from '@/components/generic/forms/FormButtons';
import {
  SelectMultipleInput,
  SelectSingleInput,
  TextInput,
} from '@/components/generic/forms/FormFields';
import { keyResultMetrics } from './keyResult_metrics';
import { useGetAreas, useGetUsers } from '@/hooks';
import {
  useCreateKeyResult,
  useUpdateKeyResult,
} from '@/hooks/key_result/key_result';
import formatOptions from '@/util/formatOptions';
import Close from '@mui/icons-material/Close';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton
} from '@mui/material';

import { useRouter } from 'next/router';
import { useState } from 'react';
import * as yup from 'yup';



const keyResultUnit = [
  {label: 'R$', value: 'R$'},
  {label: '%', value: '%'},
  {label: '$', value: '$'},
  {label: 'milhar', value: 'milhar'},
  {label: 'milhão', value: 'milhão'},
  {label: '', value: ''}
];

function KeyResultForm(props) {
  const {onClose, keyResult} = props;
  const [, setStateSelected] = useState(null);
  const [metricType, setMetricType] = useState(null);

  const {data: userResults} = useGetUsers();
  const {data: areaResults} = useGetAreas();

  const router = useRouter();

  const keyResultUpdateMutation = useUpdateKeyResult();

  const KeyResultCreateMutation = useCreateKeyResult();
  const binary = keyResult?.metric_type == 'binary' || metricType == 'binary' 
  const isEditingPage = !!keyResult;

  return (
    <Form
      handleSubmit={(values) => {
        const copyValues = {...values};

        if (copyValues.unit === '') {
          copyValues.unit = null;
        }

        if (values.metric_type === 'binary') {
          copyValues.base_value = 0;
          copyValues.target_value = 1;
        }
        isEditingPage
          ? keyResultUpdateMutation.mutateAsync({...keyResult, ...copyValues})
          : KeyResultCreateMutation.mutateAsync({...copyValues, objective_id: router.query.id});
        onClose ? onClose() : router.push('/');
      }}
    >
      <Card
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          mt: '24px',
        }}
      >
        {onClose && (
          <CardHeader
            title={isEditingPage ? 'Editar Key Result' : 'Criar novo Key Result'}
            action={
              onClose && (
                <IconButton aria-label="settings" onClick={onClose}>
                  <Close />
                </IconButton>
              )
            }
          />
        )}
        <Divider />
        <CardContent>
          <Grid
            container
            alignItems="center"
          >
          <TextInput
            label="Nome do Key Result"
            name="name"
            initialValue={keyResult?.name}
            validation={yup.string().required('Valor é obrigatório')}
          />
            <Grid item xs={12}>
              <SelectSingleInput
                label="Tipo de métrica"
                options={Object.entries(keyResultMetrics).map(([value, { label }]) => ({
                  label,
                  value,
                }))
              }
                validation={yup.string().required('Valor é obrigatório')}
                variant="standard"
                name="metric_type"
                disabled
                initialValue={keyResult?.metric_type}
                changeListener={(value) => {
                  setMetricType(value);
                }}
              />
            </Grid>

            { !binary && (
            <>
            <Grid item xs={12}>
              <SelectSingleInput
                  options={keyResultUnit}
                  label="Unidade"
                  name="unit"
                  disabled
                  initialValue={keyResult?.unit}
                  changeListener={(value) => {
                    setStateSelected(value);
                  }}
                />
            </Grid>
                <Grid item xs={12}>
                <TextInput
                      name="base_value"
                      label="Valor base"
                      variant="filled"
                      initialValue={keyResult?.base_value}
                      validation={yup
                        .number()
                        .typeError('Valor deve ser um número válido')
                        .required('Valor é obrigatório')}
                    />
                </Grid>
                <Grid item xs={12}>
                <TextInput
                      name="target_value"
                      label="Valor alvo"
                      variant="filled"
                      initialValue={keyResult?.target_value}
                      validation={yup
                        .number()
                        .typeError('Valor deve ser um número válido')
                        .required('Valor é obrigatório')}
                    />
                </Grid>
            </>
             )
            }
            <Grid item xs={12}>
              <TextInput
                name="weight"
                label="Peso do Key Result"
                variant="filled"
                initialValue={keyResult?.weight ?? 1}
                validation={yup
                  .number()
                  .typeError('Peso deve ser um número válido')
                  .required('Peso é obrigatório')}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectSingleInput
                  options={formatOptions(userResults?.results)}
                  label="Responsável"
                  initialValue={keyResult?.owner_id}
                  variant="standard"
                  name="owner_id"
                  changeListener={(value) => {
                    setStateSelected(value);
                  }}
                />
            </Grid>
            <Grid item xs={12}>
            <SelectMultipleInput
              label="Participantes"
              options={formatOptions(userResults?.results)}
              initialValue={keyResult?.participants}
              variant="standard"
              name="participants"
              changeListener={(value) => {
                setStateSelected(value);
              }}
            />
            </Grid>
            <Grid item xs={12}>
            <SelectSingleInput
              label="Área"
              options={formatOptions(areaResults)}
              initialValue={keyResult?.area_id}
              variant="standard"
              name="area_id"
              changeListener={(value) => {
                setStateSelected(value);
              }}
            />
            </Grid>
            
            <Grid item xs={12}>
            <TextInput
              label="Descrição"
              name="description"
              multiline
              rows={2}
              initialValue={keyResult?.description}
            />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            paddingX: '48px',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormSubmitButton color="primary" variant="contained">
            {`${isEditingPage ? 'Editar' : 'Criar'} Key Result`}
          </FormSubmitButton>
        </CardActions>
      </Card>
    </Form>
  );
}

export default KeyResultForm;
