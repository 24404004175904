import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import {
  useCustomGetArrayRequest,
  useDeleteRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest,
} from '../base_hooks';

export function useGetKeyResult(key_result_id, successCallback, errorCallback) {
  return useGetRequest(
    ['key-result', key_result_id],
    successCallback,
    errorCallback
  );
}

export function useCreateKeyResult(successCallback, errorCallback) {
  return usePostRequest(
    ['key-result'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['objective']});
    },
    errorCallback
  );
}


export function useUpdateKeyResult(successCallback, errorCallback) {
  return usePutRequest(
    [`key-result`],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['objective']});
      queryClient.invalidateQueries({queryKey: ['key-result', oldData.id]});
    },
    errorCallback
  );
}

export function useDeleteKeyResult(successCallback, errorCallback) {
  const router = useRouter();

  return useDeleteRequest(
    ['key-result'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['objective']});
      queryClient.removeQueries({queryKey: ['key-result', oldData.id]});
      router.push('/');
    },
    errorCallback
  );
}

export function useGetLateCheckInKeyResults(params) {
  const notNullParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => !!v)
  );
  return useCustomGetArrayRequest(['key-result'], {
    checkin_before_than__REMOVE_REFETCH: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
    ...notNullParams,
  });
}

export function useGetProgessKeyResults(params) {
  const notNullParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => !!v)
  );

  return useCustomGetArrayRequest(['key-result'], {
    progress_less_than__REMOVE_REFETCH: 50,
    ...notNullParams,
  });
}
