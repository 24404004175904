import { Typography } from "@mui/material"

export default function CurrentValuePrinter({current_value, metric_type, unit, lineHeight}) {
    if(metric_type == 'binary') {
      const binaryLabels = [{value: "Pendente", color: "error"}, {value: "Feito", color: "primary"}]
      return (
      <Typography variant='title-medium' color={binaryLabels[current_value]?.color} 
      sx={{lineHeight: lineHeight, verticalAlign: 'center'}}> 
        {binaryLabels[current_value]?.value}
      </Typography>
      )
    }
      
    if(unit == 'R$' || unit == '$') return `${unit} ${current_value}`
    
    if(!unit) return current_value
  
    return `${current_value} ${unit}`
  }