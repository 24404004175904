import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Chip, MenuItem, Select} from '@mui/material';
import {useState} from 'react';
export const ChipSelect = ({
  value,
  onChange,
  options,
  defaultLabel = 'Selecione uma opção',
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const selectedOption = options.find((option) => option.value === value);
  if (selectedOption?.icon) {
    props.icon = selectedOption.icon;
  }
  if (selectedOption?.color) {
    props.color = selectedOption.color;
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      renderValue={() => (
        <Chip
          label={selectedOption?.label ?? defaultLabel}
          onDelete={handleOpen}
          deleteIcon={<ArrowDropDownIcon size={12} />}
          onClick={handleOpen}
          sx={{
            '& .MuiChip-deleteIcon': {
              color: 'inherit',
            },
            px: '16px',
            py: '8px',
          }}
          {...props}
        />
      )}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiSelect-select': {
          padding: 0,
          paddingRight: '0 !important',
        },
        '&.MuiOutlinedInput-root': {
          backgroundColor: 'transparent',
          fieldset: {
            border: 'none',
          },
          '&:hover fieldset': {
            border: 'none',
          },
          '&.Mui-focused fieldset': {
            border: 'none',
          },
        },
        '& .MuiSelect-icon': {
          display: 'none',
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            mt: 1,
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
          },
        },
      }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{color: option.color}}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
