/* eslint-disable @next/next/no-img-element */
import CodeIcon from '@mui/icons-material/Code';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { Box, IconButton, Paper, Toolbar } from '@mui/material';
import isHotkey from 'is-hotkey';
import { useMemo, useState } from 'react';
import { Editor, Element as SlateElement, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { jsx } from 'slate-hyperscript';
import {
  Editable,
  ReactEditor,
  Slate,
  useFocused,
  useSelected,
  useSlate,
  withReact,
} from 'slate-react';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const BlockButton = ({format, icon}) => {
  const editor = useSlate();
  return (
    <IconButton
      sx={{
        color: isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
        )
          ? 'neutral.1000'
          : 'neutral.600',
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </IconButton>
  );
};

const MarkButton = ({format, icon}) => {
  const editor = useSlate();
  return (
    <IconButton
      sx={{
        color: isMarkActive(editor, format) ? 'neutral.1000' : 'neutral.600',
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </IconButton>
  );
};

const formatIconMap = {
  bold: {format: 'bold', icon: <FormatBoldIcon />, buttonType: 'mark'},
  italic: {
    format: 'italic',
    icon: <FormatItalicIcon />,
    buttonType: 'mark',
  },
  underline: {
    format: 'underline',
    icon: <FormatUnderlinedIcon />,
    buttonType: 'mark',
  },
  code: {format: 'code', icon: <CodeIcon />, buttonType: 'mark'},
  'heading-one': {
    format: 'heading-one',
    icon: <LooksOneIcon />,
    buttonType: 'block',
  },
  'heading-two': {
    format: 'heading-two',
    icon: <LooksTwoIcon />,
    buttonType: 'block',
  },
  'numbered-list': {
    format: 'numbered-list',
    icon: <FormatListNumberedIcon />,
    buttonType: 'block',
  },
  'bulleted-list': {
    format: 'bulleted-list',
    icon: <FormatListBulletedIcon />,
    buttonType: 'block',
  },
  left: {
    format: 'left',
    icon: <FormatAlignLeftIcon />,
    buttonType: 'block',
  },
  center: {
    format: 'center',
    icon: <FormatAlignCenterIcon />,
    buttonType: 'block',
  },
  right: {
    format: 'right',
    icon: <FormatAlignRightIcon />,
    buttonType: 'block',
  },
  justify: {
    format: 'justify',
    icon: <FormatAlignJustifyIcon />,
    buttonType: 'block',
  },
};

const ELEMENT_TAGS = {
  A: (el) => ({type: 'link', url: el.getAttribute('href')}),
  BLOCKQUOTE: () => ({type: 'quote'}),
  H1: () => ({type: 'heading-one'}),
  H2: () => ({type: 'heading-two'}),
  H3: () => ({type: 'heading-three'}),
  H4: () => ({type: 'heading-four'}),
  H5: () => ({type: 'heading-five'}),
  H6: () => ({type: 'heading-six'}),
  IMG: (el) => ({type: 'image', url: el.getAttribute('src')}),
  LI: () => ({type: 'list-item'}),
  OL: () => ({type: 'numbered-list'}),
  P: () => ({type: 'paragraph'}),
  PRE: () => ({type: 'code'}),
  UL: () => ({type: 'bulleted-list'}),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({code: true}),
  DEL: () => ({strikethrough: true}),
  EM: () => ({italic: true}),
  I: () => ({italic: true}),
  S: () => ({strikethrough: true}),
  STRONG: () => ({bold: true}),
  U: () => ({underline: true}),
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = {type: format, children: []};
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType) => {
  const {selection} = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = (props) => {
  const {attributes, children, element} = props;
  const style = {textAlign: element.align};
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>;
    case 'link':
      return (
        <a href={element.url} {...attributes}>
          {children}
        </a>
      );
    case 'image':
      return <ImageElement {...props} />;
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const ImageElement = ({attributes, children, element}) => {
  const selected = useSelected();
  const focused = useFocused();
  const imageStyle = {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '20em',
    boxShadow: selected && focused ? '0 0 0 2px blue' : 'none',
  };

  return (
    <div {...attributes}>
      {children}
      <img alt="" src={element.url} style={imageStyle} />
    </div>
  );
};

const Leaf = ({attributes, children, leaf}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  return <span {...attributes}>{children}</span>;
};

const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const {nodeName} = el;
  let parent = el;

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0];
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat();

  if (children.length === 0) {
    children = [{text: ''}];
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx('text', attrs, child));
  }

  return children;
};

const withHtml = (editor) => {
  const {insertData, isInline, isVoid} = editor;

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const html = data.getData('text/html');

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};

export function RichTextFunctionalInput({
  name,
  onBlur,
  placeholder,
  toolbarButtons = [],
  onChange,
  value,
  initialValue,
  error,
  helperText,
  ...props
}) {
  const editor = useMemo(
    () => withHtml(withHistory(withReact(createEditor()))),
    []
  );
  const renderLeaf = (props) => <Leaf {...props} />;
  const renderElement = (props) => <Element {...props} />;
  const [isFocused, setIsFocused] = useState(false);

  const onChangeSerializer = (originalValue) => {
    // Serialize the value to HTML string
    const htmlString = JSON.stringify(originalValue);
    onChange(htmlString);
  };

  if (typeof initialValue === 'string') {
    try {
      initialValue = JSON.parse(initialValue);
    } catch (e) {
      initialValue = [{type: 'paragraph', children: [{text: ''}]}];
    }
  }

  const defaultToolbarButtons = [
    'bold',
    'italic',
    'underline',
    'code',
    'heading-one',
    'heading-two',
    'numbered-list',
    'bulleted-list',
    'left',
    'center',
    'right',
    'justify',
  ];

  const filteredToolbarButtons =
    toolbarButtons.length > 0 ? toolbarButtons : defaultToolbarButtons;

  return (
    <Paper
      sx={{
        border: '1px solid',
        borderRadius: '4px',
        boxShadow: 'none',
        borderColor: '#ced4da',
        borderWidth: '1px',
        outline: isFocused ? '2px solid' : 'none',
        outlineColor: '#093fcf',
        outlineOffset: '-1px',
        '& > div': {
          borderColor: isFocused ? 'neutral.1000' : '#ced4da',
        },
        '&:hover': {
          borderColor: 'neutral.1000',
          '& > div': {
            borderColor: 'neutral.1000',
          },
        },
      }}
      {...props}
    >
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={onChangeSerializer}
      >
        <Box
          sx={{
            p: 1,
            display: 'flex',
            borderBottom: '1px solid',
          }}
        >
          <Toolbar sx={{'&.MuiToolbar-root': {minHeight: '40px', padding: 0}}}>
            {filteredToolbarButtons.map((format) => {
              const buttonProps = formatIconMap[format];
              if (buttonProps) {
                const {format: btnFormat, icon, buttonType} = buttonProps;
                if (buttonType == 'mark') {
                  return (
                    <MarkButton key={format} format={btnFormat} icon={icon} />
                  );
                } else {
                  return (
                    <BlockButton key={format} format={btnFormat} icon={icon} />
                  );
                }
              }
              return null;
            })}
          </Toolbar>
        </Box>
        <Box
          sx={{p: 1}}
          onMouseDown={(event) => {
            event.preventDefault();
            if (document.activeElement !== event.currentTarget) {
              ReactEditor.focus(editor);
            }
          }}
        >
          <Editable
            placeholder={placeholder ?? 'Escreva aqui'}
            onBlur={() => {
              setIsFocused(false);
              const syntheticEvent = {persist: () => {}, target: {name}};
              onBlur(syntheticEvent);
            }}
            onFocus={() => setIsFocused(true)}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck
            style={{outline: 'none'}}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              ReactEditor.focus(editor);
            }}
          />
        </Box>
      </Slate>
    </Paper>
  );
}

export const RichTextDisplay = ({savedRichText}) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderElement = (props) => <Element {...props} />;
  if (typeof savedRichText === 'string') {
    savedRichText = JSON.parse(savedRichText);
  }
  return (
    <Slate editor={editor} initialValue={savedRichText}>
      <Editable readOnly renderElement={renderElement} />
    </Slate>
  );
};
