import {
  useCustomGetArrayRequest,
  useDeleteRequest,
  useGetRequest,
  usePostRequest,
  usePutRequest
} from '../base_hooks';
export function useGetObjectives(params) {
  if (!params) params = {};
  const notNullParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => !!v)
  );
  const result = useCustomGetArrayRequest(['objective'], notNullParams);
  return result;
}

export function useGetObjective(id) {
  return useGetRequest(['objective', id], null, {
    enabled: id != null,
  });
}

export function useCreateObjective(successCallback, errorCallback) {
  return usePostRequest(
    ['objective'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['objective']});
    },
    errorCallback
  );
}

export function useUpdateObjective(successCallback, errorCallback) {
  return usePutRequest(
    ['objective'],
    (queryClient, oldData, newData) => {
      if (successCallback) successCallback(queryClient, oldData, newData);
      queryClient.invalidateQueries({queryKey: ['objective']});
    },
    errorCallback
  );
}


export function useDeleteObjective(successCallback, errorCallback) {
  return useDeleteRequest(['objective'], successCallback, errorCallback);
}
