export const keyResultMetrics = {
  "increase": {
    label: 'Aumentar valor'
  },
  "decrease": {
    label: 'Diminuir valor'
  },
  "keep_above": {
    label: 'Manter valor acima de'
  },
  "keep_below": {
    label: 'Manter valor abaixo de'
  },
  "binary": {
    label: 'Binário'
  }
}


