import { Form } from '@/components/generic/forms/Form';
import { FormSubmitButton } from '@/components/generic/forms/FormButtons';
import { DateInput, TextInput } from '@/components/generic/forms/FormFields';
import { confidenceEnum } from '@/config/confidence_enum';
import { useCreateCheckIn } from '@/hooks/key_result/checkin';
import Close from '@mui/icons-material/Close';
import { Button, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { ErrorMessage } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { ChipSelect } from '../generic/Chip/ChipSelect';



export function CheckInCard(props) {
  const {onClose, keyResult} = props;
  const {id, name, base_value, target_value, current_confidence, metric_type} = keyResult;

  const [confidence, setConfidence] = useState(current_confidence);
  const [binary, setBinary] = useState(keyResult.current_value)

  const createCheckInMutation = useCreateCheckIn(id);
  const now = dayjs();

  const binaryStatus = {
    1: {
      label: "Feito",
      color: "primary.main"
    },
    0: {
      label: "Pendente",
      color: "error.main"
    }
  };

  return (
    <Form
      handleSubmit={(values) => {
        const {note} = values;
        const current_value = metric_type == 'binary' ? binary : values.current_value

        return createCheckInMutation
          .mutateAsync({
            current_value,
            note,
            key_result: id,
            confidence,
          })
          .then(() => {
            onClose && onClose();
          });
      }}
    >
      <Card
        sx={{
          height: '100%',
          m: '0 auto',
          maxHeight: '90vh',
          overflow: 'auto',
          maxWidth: '800px',
        }}
      >
        <CardHeader
          title="Check-in"
          subheader={name}
          action={
            <IconButton aria-label="settings" onClick={onClose}>
              <Close />
            </IconButton>
          }
        />
        <CardContent>
          <Divider />
          <Stack
            direction={{xs: 'column', md: 'row'}}
            paddingX={4}
            paddingTop={2}
            justifyContent="space-between"
          >
            <Stack gap={1} sx={{width: '100%', alignItems: 'center'}}>
              <DateInput name="date" label="Data" initialValue={now} disabled />
                {
                  metric_type != 'binary' ?
                <>
                  <TextInput
                    name="base_value"
                    label="Valor base"
                    initialValue={base_value}
                    disabled
                  />

                  <TextInput
                    name="target_value"
                    label="Valor alvo"
                    initialValue={target_value}
                    disabled
                  />
                  
                  <TextInput
                    name="current_value"
                    label="Valor atual"
                    validation={yup
                      .number()
                      .typeError('Valor deve ser um número válido')
                      .required('Valor é obrigatório')}
                    /> 
                </> :  
                  <ChipSelect
                    contrastText="#fff"
                    variant="outlined"
                    value={binary} 
                    options={Object.entries(binaryStatus).map(([key, status]) => ({
                      value: Number(key), 
                      label: (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            fontWeight: 'bold',
                            color: status.color
                          }}
                        >
                          {status.label}
                        </Typography>
                      )
                    }))}
                    onChange={(event) => {
                      const newValue = event.target.value; 
                      setBinary(Number(newValue)); 
                    }} 
                    sx={{
                      border: '1px solid',
                      borderColor: binaryStatus[binary]?.color,
                      height: '40px',
                      width: '160px',
                      px: '8px'
                    }}
                  />
                }
                
              <Stack justifyContent="center" gap={2} direction="row" sx={{my: "15px"}}>
                <Button
                  variant={confidence === 'on_track' ? 'contained' : 'outlined'}
                  color={confidenceEnum['on_track'].color}
                  onClick={() => setConfidence('on_track')}
                >
                  {confidenceEnum['on_track'].text}
                </Button>
                <Button
                  variant={confidence === 'at_risk' ? 'contained' : 'outlined'}
                  color={confidenceEnum['at_risk'].color}
                  onClick={() => setConfidence('at_risk')}
                >
                  {confidenceEnum['at_risk'].text}
                </Button>
                <Button
                  variant={
                    confidence === 'off_track' ? 'contained' : 'outlined'
                  }
                  color={confidenceEnum['off_track'].color}
                  onClick={() => setConfidence('off_track')}
                >
                  {confidenceEnum['off_track'].text}
                </Button>
                <ErrorMessage name="confidence" component="div" />
              </Stack>
            </Stack>
          </Stack>
            <TextInput
              name="note"
              label="Comentários"
              multiline
              rows={4}
              fontSize={12}
              placeholder="Como chegou até onde está hoje? - Há algo que precisa fazer de maneira diferente? - Tem algum pedido para a equipe?"
            />
        </CardContent>
        <CardActions
          sx={{
            paddingX: '48px',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormSubmitButton color="primary" variant="contained">
            Realizar Check-in
          </FormSubmitButton>
        </CardActions>
      </Card>
    </Form>
  );
}

export default CheckInCard;
