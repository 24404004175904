export const primaryColor = {
  lighter: '#094839',
  light: '#094839',
  main: '#094839',
  dark: '#0f785f',
  darker: '#094839',
  contrastText: '#fff',
};

export const secondaryColor = {
  lighter: '#466af7',
  light: '#466af7',
  main: '#093fcf',
  dark: '#002486',
  darker: '#001354',
  contrastText: '#fff',
};

export const neutralColor = {
  main: '#edeff5',
  0: '#ffffff',
  100: '#f4f6fa',
  200: '#edeff5',
  300: '#edeff5',
  400: '#d8dae5',
  500: '#d8dae5',
  600: '#8f95b2',
  700: '#696f8c',
  800: '#474d66',
  900: '#252836',
  1000: '#000000',
};

export const errorColor = {
  main: '#da2424',
  light: '#f05252',
  dark: '#a70f0f',
};
