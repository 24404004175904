import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import RemoveIcon from '@mui/icons-material/Remove';
import { Stack, Typography } from '@mui/material';

const statusConfig = {
  on_track: {
    color: '#1E8038',
    backgroundColor: '#D3FCDE',
    StatusIcon: ArrowDropUpIcon,
    signal: '+',
  },
  at_risk: {
    color: '#F4AB34',
    backgroundColor: '#FFEFD2',
    StatusIcon: RemoveIcon,
    signal: '',
  },
  off_track: {
    color: '#A70F0F',
    backgroundColor: '#FCD2D2',
    StatusIcon: ArrowDropDownIcon,
    signal: '',
  },
};

function KeyResultCheckInStatus(props) {
  const {progressHistory} = props;

  if (!progressHistory.length) {
    return (
      <Stack
        sx={{
          backgroundColor: 'gray',
          padding: '2px 8px',
          borderRadius: '100px',
          width: 'fit-content',
        }}
      >
        <Typography
          fontWeight={600}
          whiteSpace="nowrap"
          color="white"
          fontSize={12}
        >
          0 %
        </Typography>
      </Stack>
    );
  }

  const calculatedProgress =
    (progressHistory?.at(-1)?.progress ?? 0) -
    (progressHistory?.at(-2)?.progress ?? 0);

  const confidence =
    calculatedProgress > 0
      ? 'on_track'
      : calculatedProgress < 0
      ? 'off_track'
      : 'at_risk';

  const progressStatus = statusConfig[confidence];
  const {color, backgroundColor, StatusIcon, signal} = progressStatus;

  return (
    <Stack
      sx={{
        backgroundColor,
        padding: '2px 8px 2px 2px',
        borderRadius: '100px',
        flexWrap: 'nowrap',
        width: 'fit-content',
      }}
      direction="row"
      alignItems="center"
    >
      <StatusIcon sx={{color}} fontSize="small" />
      <Typography
        color={color}
        fontSize={12}
        fontWeight={600}
        whiteSpace="nowrap"
      >
        {signal}
        {Number(calculatedProgress.toPrecision(2))} %
      </Typography>
    </Stack>
  );
}

export default KeyResultCheckInStatus;
